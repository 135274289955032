<template>
    <div class="depquota">
        <div class="statistics_box">
            <div class="tm" v-if="btnP.tmMail">
                <p class="title">
                    <span class="iconfont icon-tengxunqiyeyouxiang"></span>
                    腾讯企业邮箱
                </p>
                <div class="content_box">
                    <p>
                        <span>{{
                            tmAccount.maxReportNumber
                                ? tmAccount.maxReportNumber
                                : 0
                        }}</span>
                        <span>当前可最大报备数</span>
                    </p>
                    <p>
                        <span>{{
                            tmAccount.consumedNumber
                                ? tmAccount.consumedNumber +
                                  tmAccount.channelReportNumber
                                : 0
                        }}</span>
                        <span>已消耗名额</span>
                    </p>
                    <p>
                        <span>{{
                            tmAccount.assignedNumber
                                ? tmAccount.assignedNumber
                                : 0
                        }}</span>
                        <span>已分配名额</span>
                    </p>
                    <p>
                        <span>{{
                            tmAccount.maxReportNumber - tmAccount.assignedNumber
                                ? tmAccount.maxReportNumber -
                                  tmAccount.assignedNumber
                                : 0
                        }}</span>
                        <span>可分配名额</span>
                    </p>
                </div>
            </div>
            <div class="ali" v-if="btnP.aliMail" style="margin: 0 10px">
                <p class="title">
                    <span class="iconfont icon-alimailaliyouxiang"></span
                    >阿里企业邮箱
                </p>
                <div class="content_box">
                    <p>
                        <span>{{
                            aliAccount.maxReportNumber
                                ? aliAccount.maxReportNumber
                                : 0
                        }}</span>
                        <span>当前可最大报备数</span>
                    </p>
                    <p>
                        <span>{{
                            aliAccount.consumedNumber
                                ? aliAccount.consumedNumber +
                                  aliAccount.channelReportNumber
                                : 0
                        }}</span>
                        <span>已消耗名额</span>
                    </p>
                    <p>
                        <span>{{
                            aliAccount.assignedNumber
                                ? aliAccount.assignedNumber
                                : 0
                        }}</span>
                        <span>已分配名额</span>
                    </p>
                    <p>
                        <span>{{
                            aliAccount.maxReportNumber -
                            aliAccount.assignedNumber
                                ? aliAccount.maxReportNumber -
                                  aliAccount.assignedNumber
                                : 0
                        }}</span>
                        <span>可分配名额</span>
                    </p>
                </div>
            </div>
            <div class="wy" v-if="btnP.wyMail">
                <p class="title">
                    <span class="iconfont icon-alimailaliyouxiang"></span
                    >网易企业邮箱
                </p>
                <div class="content_box">
                    <p>
                        <span>{{
                            wyAccount.maxReportNumber
                                ? wyAccount.maxReportNumber
                                : 0
                        }}</span>
                        <span>当前可最大报备数</span>
                    </p>
                    <p>
                        <span>{{
                            wyAccount.consumedNumber
                                ? wyAccount.consumedNumber +
                                  wyAccount.channelReportNumber
                                : 0
                        }}</span>
                        <span>已消耗名额</span>
                    </p>
                    <p>
                        <span>{{
                            wyAccount.assignedNumber
                                ? wyAccount.assignedNumber
                                : 0
                        }}</span>
                        <span>已分配名额</span>
                    </p>
                    <p>
                        <span>{{
                            wyAccount.maxReportNumber - wyAccount.assignedNumber
                                ? wyAccount.maxReportNumber -
                                  wyAccount.assignedNumber
                                : 0
                        }}</span>
                        <span>可分配名额</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="search">
            <span>部门筛选</span>
            <el-cascader
                size="small"
                ref="cascader"
                v-model="dempVal"
                :options="dempData"
                clearable
                style="width: 200px; margin-right: 20px"
                :show-all-levels="false"
                filterable
                @change="changeA"
                :props="{
                    checkStrictly: true,
                    expandTrigger: 'hover',
                    emitPath: false,
                    value: 'id',
                    label: 'departmentName',
                    children: 'children',
                }"
            >
                <template slot-scope="{ node, data }">
                    <div @click="cascaderClick(data)">
                        <span>{{ data.departmentName }}</span>
                    </div>
                </template>
            </el-cascader>

            <span>品牌分类</span>
            <el-radio-group
                size="small"
                v-model="returnStatusVal"
                @change="(currentPage = 1), (pagesize = 20)"
            >
                <el-radio-button
                    label="腾讯"
                    v-if="btnP.tmMail"
                ></el-radio-button>
                <el-radio-button
                    label="阿里"
                    v-if="btnP.aliMail"
                ></el-radio-button>
                <el-radio-button
                    label="网易"
                    v-if="btnP.wyMail"
                ></el-radio-button>
            </el-radio-group>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="7"> </el-table-column>
            <el-table-column
                label="部门名称"
                width="80"
                prop="departmentName"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="部门当前可最大报备数"
                show-overflow-tooltip
                width="192"
            >
                <template slot-scope="scope">
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '腾讯'"
                        >{{
                            btnP.tmReport
                                ? scope.row.consumedNumberChanceTencent
                                : scope.row.maxReportNumberTencent
                        }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '阿里'"
                        >{{ scope.row.maxReportNumberAli }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '网易'"
                        >{{ scope.row.maxReportNumberNetease }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="已申请报备数"
                width="120"
                prop="consumedNumberTencent"
                show-overflow-tooltip
                ><template slot-scope="scope">
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '腾讯'"
                        >{{
                            btnP.tmReport
                                ? scope.row.consumedNumberChanceTencent
                                : scope.row.consumedNumberTencent +
                                  scope.row.channelReportNumberTencent
                        }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '阿里'"
                        >{{
                            scope.row.consumedNumberAli +
                            scope.row.channelReportNumberAli
                        }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '网易'"
                        >{{
                            scope.row.consumedNumberNetease +
                            scope.row.channelReportNumberNetease
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="剩余可报备数"
                width="120"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '腾讯'"
                        >{{
                            btnP.tmReport
                                ? scope.row.maxReportNumberChanceTencent -
                                  scope.row.consumedNumberChanceTencent
                                : scope.row.maxReportNumberTencent -
                                  (scope.row.consumedNumberTencent +
                                      scope.row.channelReportNumberTencent)
                        }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '阿里'"
                        >{{
                            scope.row.maxReportNumberAli -
                            (scope.row.consumedNumberAli +
                                scope.row.channelReportNumberAli)
                        }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '网易'"
                        >{{
                            scope.row.maxReportNumberNetease -
                            (scope.row.consumedNumberNetease +
                                scope.row.channelReportNumberNetease)
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        v-if="btnP.adjust"
                        type="text"
                        style="color: #2370eb; margin-right: 15px"
                        @click="onAdjust(scope.row)"
                        >调整</el-button
                    >
                    <el-button
                        v-if="btnP.reset"
                        type="text"
                        style="color: #2370eb"
                        @click="onReset(scope.row)"
                        >重置</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 重置 -->
        <el-dialog
            :visible.sync="dialogReset"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    重置报备数
                </div>
            </template>
            <div class="content">
                <p>
                    <span
                        ><i class="iconfont icon-shangchuanshibaixian-copy"></i
                    ></span>
                    <br />
                    即将清零该部门（{{
                        i == 1 ? '腾讯' : i == 2 ? '阿里' : ''
                    }}企业邮箱）当前可最大报备数<br />
                    重置后该部门在调整报备数前将无法报备，是否确认？
                </p>
            </div>
            <div class="but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="handleClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >确定</el-button
                >
            </div>
        </el-dialog>
        <!-- 调整名额 -->
        <el-dialog
            :visible.sync="dialogAdjust"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    调整名额
                </div>
            </template>
            <div>
                <Adjust ref="adjust" @close="handleClose" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Adjust from './adjust.vue';
import { accountBrief } from '@/api/report/report';
import { accountBrief as newAccountBrief } from '@/api/report/newReport';
import { depmList, depmUpdate, depmReset } from '@/api/depquota/depquota';
import { departmentList } from '@/api/admin/framework/framework.js';
export default {
    components: {
        Adjust,
    },
    data() {
        return {
            tableData: [],
            total: 0,
            currentPage: 1,
            pagesize: 20,
            dempData: [],
            dempVal: '',
            dialogAdjust: false,
            dialogReset: false,
            row: {},
            i: '',
            btnP: {},
            tmAccount: {},
            wyAccount: {},
            returnStatusVal: '腾讯',
            aliAccount: {},
        };
    },
    mounted() {
        this.getBtn();
        this.getAccountBrief();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            if (btn.tmMail && !btn.aliMail && !btn.wyMail) {
                this.returnStatusVal = '腾讯';
            }
            if (btn.aliMail && !btn.tmMail && !btn.wyMail) {
                this.returnStatusVal = '阿里';
            }
            if (btn.wyMail && !btn.tmMail && !btn.aliMail) {
                this.returnStatusVal = '网易';
            }
            if (!btn.tmMail && btn.aliMail && btn.wyMail) {
                his.returnStatusVal = '阿里';
            }
            this.getDempData();

            this.getData();
        },
        getAccountBrief() {
            let data = {
                param: {
                    productType: 1,
                },
            };
            if (this.btnP.tmReport) {
                data.param.productType = 5;
                newAccountBrief(data).then((res) => {
                    this.tmAccount = res.data;
                });
            } else {
                accountBrief(data).then((res) => {
                    this.tmAccount = res.data;
                });
            }

            let obj = {
                param: {
                    productType: 2,
                },
            };
            accountBrief(obj).then((res) => {
                this.aliAccount = res.data;
            });
            let obj1 = {
                param: {
                    productType: 4,
                },
            };
            accountBrief(obj1).then((res) => {
                this.wyAccount = res.data;
            });
        },
        cascaderClick(nodeData) {
            this.dempVal = nodeData.id;
            this.getData();
            this.$refs.cascader.checkedValue = nodeData.departmentName;
            this.$refs.cascader.computePresentText();
            this.$refs.cascader.toggleDropDownVisible(false);
        },
        changeA() {
            this.dempVal = '';
            this.getData();
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = this.$Tree.treeDataTranslate(res.data);
            });
        },
        getData() {
            let data = {
                param: {},
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.dempVal != '') {
                data.param.id = this.dempVal;
            }

            depmList(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
            });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },

        // 调整
        onAdjust(row) {
            let i = 1;
            this.dialogAdjust = true;
            if (this.returnStatusVal == '腾讯') {
                if (this.btnP.tmReport) {
                    i = 5;
                } else {
                    i = 1;
                }
            }
            if (this.returnStatusVal == '阿里') {
                i = 2;
            }
            if (this.returnStatusVal == '网易') {
                i = 4;
            }
            setTimeout(() => {
                this.$refs.adjust.getData(row, i);
            });
        },
        //重置
        onReset(row) {
            this.row = row;
            if (this.returnStatusVal == '腾讯') {
                if (this.btnP.tmReport) {
                    this.i = 5;
                } else {
                    this.i = 1;
                }
            }
            if (this.returnStatusVal == '阿里') {
                this.i = 2;
            }
            if (this.returnStatusVal == '网易') {
                this.i = 4;
            }
            this.dialogReset = true;
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.row.id,
                },
            };
            if (this.i == 1) {
                data.param.maxReportNumberTencent = 0;
            }
            if (this.i == 2) {
                data.param.maxReportNumberAli = 0;
            }
            if (this.i == 4) {
                data.param.maxReportNumberNetease = 0;
            }
            if (this.i == 5) {
                data.param.maxReportNumberChanceTencent = 0;
            }
            depmReset(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        handleClose() {
            this.dialogAdjust = false;
            this.dialogReset = false;
            this.getData();
            this.getAccountBrief();
        },
    },
};
</script>

<style lang="scss" scoped>
.depquota {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .statistics_box {
        width: 100%;
        display: flex;
        margin-bottom: 12px;
        height: 120px;
        .tm {
            flex: 1;
            background: #fff;
            border-radius: 10px;
            padding: 0 10px;
            display: flex;
            flex-direction: column;
            .title span {
                color: #2370eb;
                background: #e9f2ff;
            }
            .content_box {
                background: #f7f9fd;
                p:last-child {
                    span:nth-child(1) {
                        font-size: 18px;
                        color: #333333;
                        font-weight: 600;
                        color: #2370eb;
                    }
                }
            }
        }
        .ali {
            flex: 1;
            background: #fff;
            border-radius: 10px;
            padding: 0 10px;
            display: flex;
            flex-direction: column;
            .title span {
                background: #fff0e9;
                color: #ff562d;
            }
            .content_box {
                background: #fef6f3;
                display: flex;

                p:last-child {
                    span:nth-child(1) {
                        font-size: 18px;
                        color: #333333;
                        font-weight: 600;
                        color: #ff562d;
                    }
                }
            }
        }
        .wy {
            flex: 1;
            background: #fff;
            border-radius: 10px;
            padding: 0 10px;
            display: flex;
            flex-direction: column;
            .title span {
                background: #fff0e9;
                color: #ff562d;
            }
            .content_box {
                background: #fef6f3;
                p:last-child {
                    span:nth-child(1) {
                        font-size: 18px;
                        color: #333333;
                        font-weight: 600;
                        color: #ff562d;
                    }
                }
            }
        }
        .title {
            font-size: 14px;
            font-weight: 600;
            padding: 12px 0;
            span {
                display: inline-block;
                width: 28px;
                height: 28px;
                border-radius: 28px;
                text-align: center;
                line-height: 28px;
                margin-right: 16px;
                font-weight: 500;
            }
        }
        .content_box {
            flex: 1;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-radius: 5px;
            padding-left: 10px;
            p {
                span {
                    display: block;
                }
                span:nth-child(1) {
                    font-size: 18px;
                    color: #333333;
                    font-weight: 600;
                }
                span:nth-child(2) {
                    font-size: 14px;
                    color: #333333;
                    margin-top: 7px;
                }
            }
        }
    }
    .search {
        background: #fff;
        padding: 10px 16px;
        font-size: 13px;
        font-weight: 600;
        color: #333333;
        span {
            margin-right: 12px;
        }
        button {
            background: #2370eb;
            border-radius: 2px;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.content {
    height: 200px;
    p {
        text-align: center;
        margin-top: 50px;
        font-size: 14px;
        color: #333;
        line-height: 30px;
        /deep/ .iconfont {
            color: #f5a623;
            font-size: 50px;
        }
    }
}
</style>
