import http from '@/utils/http.js'

// 商机-账号部门列表
export function depmList(data) {
    return http({
        url: "/jasoboss/department/niche/list",
        method: "post",
        data
    })
}
// 商机-账号部门修改
export function depmUpdate(data) {
    return http({
        url: "/jasoboss/department/niche/update",
        method: "post",
        data
    })
}
// 商机-账号员工修改
export function adminUpdate(data) {
    return http({
        url: "/jasoboss/admin/niche/update",
        method: "post",
        data
    })
}
// 商机-部门账号 设置显示
export function accountDepm(data) {
    return http({
        url: "/jasoboss/niche/account/brief/department/get",
        method: "post",
        data
    })
}
// 商机-员工账号 设置显示
export function accountAdmin(data) {
    return http({
        url: "/jasoboss/niche/account/brief/admin/get",
        method: "post",
        data
    })
}
// 商机-账号部门重置
export function depmReset(data) {
    return http({
        url: "/jasoboss/department/niche/reset",
        method: "post",
        data
    })
}