<template>
    <div class="adjust">
        <div class="content">
            <p>
                <span>当前可最大报备数</span>
                <span>{{ data.maxReportNumber }}</span>
            </p>
            <p>
                <span>已分配名额</span>
                <span>{{ data.assignedNumber }}</span>
            </p>
            <p>
                <span>可分配名额</span>
                <span>{{ data.maxReportNumber - data.assignedNumber }}</span>
            </p>
            <p>
                <span>部门已分配员工名额</span>
                <span>{{ data.departmentAssignedNumber }}</span>
            </p>
            <p>
                <span>部门当前可最大报备数</span>
                <el-input
                    v-model="input"
                    placeholder="请输入内容"
                    style="width: 270px"
                    size="small"
                    @input="
                        () => {
                            input = input.replace(/[^0-9]/g, '');
                        }
                    "
                ></el-input>
            </p>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { accountDepm, depmUpdate } from '@/api/depquota/depquota';
import { accountDepm as newAccountDepm } from '@/api/report/newReport';
export default {
    data() {
        return {
            input: '',
            data: {},
            row: {},
            i: 0,
        };
    },
    methods: {
        getData(row, i) {
            console.log(row);
            this.row = row;
            this.i = i;
            let data = {
                param: {
                    productType: i,
                    departmentId: row.id,
                },
            };
            if (i == 5) {
                newAccountDepm(data).then((res) => {
                    this.data = res.data;
                    this.input = row.maxReportNumberChanceTencent;
                });
            }
            accountDepm(data).then((res) => {
                this.data = res.data;
                if (i == 1) {
                    this.input = row.maxReportNumberTencent;
                }
                if (i == 2) {
                    this.input = row.maxReportNumberAli;
                }
                if (i == 4) {
                    this.input = row.maxReportNumberNetease;
                }
            });
        },
        onSubmit() {
            if (this.i == 1) {
                if (
                    Number(this.input) - this.row.maxReportNumberTencent >
                    this.data.maxReportNumber - this.data.assignedNumber
                ) {
                    return this.$message.error(
                        '员工当前可最大报备数不能大于部门可分配名额'
                    );
                }
            }
            if (this.i == 2) {
                if (
                    Number(this.input) - this.row.maxReportNumberAli >
                    this.data.maxReportNumber - this.data.assignedNumber
                ) {
                    return this.$message.error(
                        '员工当前可最大报备数不能大于部门可分配名额'
                    );
                }
            }
            if (this.i == 4) {
                if (
                    Number(this.input) - this.row.maxReportNumberNetease >
                    this.data.maxReportNumber - this.data.assignedNumber
                ) {
                    return this.$message.error(
                        '员工当前可最大报备数不能大于部门可分配名额'
                    );
                }
            }
            if (this.i == 5) {
                if (
                    Number(this.input) - this.row.maxReportNumberChanceTencent >
                    this.data.maxReportNumber - this.data.assignedNumber
                ) {
                    return this.$message.error(
                        '员工当前可最大报备数不能大于部门可分配名额'
                    );
                }
            }
            if (Number(this.input) < this.data.departmentAssignedNumber) {
                return this.$message.error(
                    '部门当前可最大报备数不能小于部门已分配名额'
                );
            }
            let data = {
                param: {
                    id: this.row.id,
                },
            };
            if (this.i == 1) {
                data.param.maxReportNumberTencent = this.input;
            }
            if (this.i == 2) {
                data.param.maxReportNumberAli = this.input;
            }
            if (this.i == 4) {
                data.param.maxReportNumberNetease = this.input;
            }
            if (this.i == 5) {
                data.param.maxReportNumberChanceTencent = this.input;
            }
            depmUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.input = '';
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.adjust {
    height: 100%;
    .content {
        padding-left: 79px;
        padding-bottom: 180px;
        p {
            padding-top: 16px;
            span:nth-child(1) {
                width: 160px;
                display: inline-block;
                color: #333333;
                font-size: 14px;
            }
            span:nth-child(2) {
                color: #2370eb;
            }
        }
    }
    .but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
}
</style>
